
import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

export default ({ location }) => {
    return (
        <Layout title="Thanks for contacting us!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thank you for your message! One of our Frends team members will reach you back soon!</h1>
                                    <Button color="blue" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}